<template>
  <v-app>
    <v-main class="grey lighten-4">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {};
</script>
<style>
* {
  word-break: normal !important;
}
</style>
